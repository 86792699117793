import * as React from "react";
import { Container, Header, Segment, Grid, Divider, Form, Button } from "semantic-ui-react";
import { withLayout } from "../components/Layout";

const ContactPage = () => {
  return (
    <Segment className="stripe alternate" padded="very">
      <Container>
        <Header as="h1" textAlign="center">
          Contact Us
        </Header>

        <Divider hidden />
        <Divider hidden />

        <Grid columns="1" stackable textAlign="center">
          <Grid.Row>
            <Grid.Column>
              <p>
                For all business inquiries, please email us at&nbsp;
                <a href="mailto:contact@latticeautomation.com"
                   style={{ color: "#0066cc", textDecoration: "underline" }}>
                    contact@latticeautomation.com
                </a> or fill out the form below.
                <br />
                <br />
                We'll get back to you as soon as we can.
                <br />
                <br />
                Note, we are not currently hiring (and will not reach out to anyone for hiring).
                <br />
                <br />
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form
                action="https://formspree.io/f/xldjpdnk" // Setup by Chris Krenz; free account: 50 submissions monthly
                method="POST"
                style={{ maxWidth: "600px", margin: "0 auto", textAlign: "left" }}
              >
                <Form.Field>
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    required
                  />
                </Form.Field>

                <Form.Field>
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    required
                  />
                </Form.Field>

                <Form.Field>
                  <label>Company</label>
                  <input
                    type="text"
                    name="company"
                  />
                </Form.Field>

                <Form.Field>
                  <label>Message</label>
                  <textarea
                    name="message"
                    required
                    style={{ height: "100px" }}
                  />
                </Form.Field>

                <Button type="submit" primary>
                  Send Message
                </Button>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default withLayout(ContactPage);
